import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import HomeIcon from "./../../assets/images/icons/leftbar/home.png";
import WCIcon from "./../../assets/images/icons/leftbar/worldcasino.png";
// import SlotsIcon from "./../../assets/images/icons/leftbar/slots.png";
// import NetentIcon from "./../../assets/images/icons/leftbar/netent.png";
// import RedTigerIcon from "./../../assets/images/icons/leftbar/redtiger.png";
import EzugiIcon from "./../../assets/images/icons/leftbar/ezugi.png";
import EvolutionIcon from "./../../assets/images/icons/leftbar/evolution.png";
import VivoIcon from "./../../assets/images/icons/leftbar/vivo.png";
import QtechIcon from "./../../assets/images/icons/leftbar/qtech.png";
import XPGIcon from "./../../assets/images/icons/leftbar/xpg.png";
import WhatsappIcon from "./../../assets/images/icons/whatsappfoot.png";

const LeftBarSports = () => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const { sportsData, oddsData, showBetSlip } = useSelector(
    (state) => state.sports
  );

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const tabs = [
    {
      id: 1,
      eventKey: "1",
      name: "In play",
    },
    {
      id: 2,
      eventKey: "2",
      name: "Cricket",
    },
    {
      id: 3,
      eventKey: "3",
      name: "Soccer",
    },
    {
      id: 4,
      eventKey: "4",
      name: "Tennis",
    },
  ];

  return (
    <>
      <div className="leftbarSec d-none d-md-block">
        <ul>
          <li className="games_link">
            <h6>Quick Links</h6>
            <ul>
              <li>
                <div
                  onClick={() => {
                    navigate("/sports");
                  }}
                >
                  <img src={HomeIcon} alt="Home Icon" />
                  <span>Home</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/worldcasino");
                  }}
                >
                  <img src={WCIcon} alt="Casino Icon" />
                  <span>World Casino</span>
                </div>
              </li>
            </ul>
          </li>

          <li className="games_link">
            <ul>
              {/* <li>
                <div
                  onClick={() => {
                    navigate("/Slots");
                  }}
                >
                  <img src={SlotsIcon} alt="Casino Icon" />
                  <span>Slots</span>
                </div>
              </li> */}
              {/* <li>
                <div
                  onClick={() => {
                    navigate("/Slots");
                  }}
                >
                  <img src={NetentIcon} alt="Casino Icon" />
                  <span>Netent</span>
                </div>
              </li> */}
              {/* <li>
                <div
                  onClick={() => {
                    navigate("/Slots");
                  }}
                >
                  <img src={RedTigerIcon} alt="Casino Icon" />
                  <span>RedTiger</span>
                </div>
              </li> */}
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/ezugi");
                  }}
                >
                  <img src={EzugiIcon} alt="Casino Icon" />
                  <span>Ezugi</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/evolution");
                  }}
                >
                  <img src={EvolutionIcon} alt="Casino Icon" />
                  <span>Evolution</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/vivo");
                  }}
                >
                  <img src={VivoIcon} alt="Casino Icon" />
                  <span>Vivo</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/qtech");
                  }}
                >
                  <img src={QtechIcon} alt="Casino Icon" />
                  <span>Qtech</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/xpg");
                  }}
                >
                  <img src={XPGIcon} alt="Casino Icon" />
                  <span>XPG</span>
                </div>
              </li>
              <li className="wtsp d-md-non">
                <div>
                  <a
                    href={
                      appDetails?.WHATS_APP_URL != ""
                        ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                        : "javascript:void(0)"
                    }
                    target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                  >
                    <img src={WhatsappIcon} alt="whatsapp" />
                    <span>Chat</span>
                  </a>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LeftBarSports;
