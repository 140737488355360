import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";

import FbIconImg from "../assets/images/provider/facebook.png";
import InstaIconImg from "../assets/images/provider/instagram.png";
import logo from "../assets/images/mmplay.png";
import AppuGames from "../assets/images/provider/Betfair.jpg";
import EvolutionGames from "../assets/images/provider/provider-logo-evolution.png";
import EzugiGames from "../assets/images/provider/provider-logo-ezugi.png";
import VivoGames from "../assets/images/provider/Vivo.jpg";
import QtechGames from "../assets/images/provider/qtech.jpg";
import WCGames from "../assets/images/provider/world.jpg";
import XPGhGames from "../assets/images/provider/xpg.jpg";

import binance from "../assets/images/payments/new/Binance.png";
import pmicon from "../assets/images/payments/new/Pm.png";
import therthnt from "../assets/images/payments/new/Tether.png";
import Bitcoin from "../assets/images/payments/new/Bitcoin.png";
import Phonepay from "../assets/images/payments/new/phonepe-logo-icon.png";
import Paytm from "../assets/images/payments/new/Paytm.png";
import GooglaPay from "../assets/images/payments/new/Gpay.png";
import UPIcon from "../assets/images/payments/new/Upi.png";

import Calling1 from "../assets/images/icons/phone.png";
import Whatapps from "../assets/images/icons/whatsapp.png";
import Telegram from "../assets/images/icons/telegram.png";
import Mail from "../assets/images/icons/mail.png";

import WhatsaPPImg from "../assets/images/icons/whatsappfoot.png";
import TelegramImg from "../assets/images/icons/telegramfoot.png";
import BottomNav from "./BottomNav";

const Footer = () => {
  const { appDetails } = useSelector((state) => state.app);

  return (
    <>
      <div className="SocialMediaSection">
        <Container>
          <ul>
            <li>
              <a href={`tel://${appDetails?.PHONE}`}>
                <img src={Calling1} alt="Calling icon" />
                <span>+91 98765 43210</span>
              </a>
            </li>
            <li>
              <a href="mailto:info.mmplay@gmail.com">
                <img src={Mail} alt="Calling icon" />
                <span>info.mmplay@gmail.com</span>
              </a>
            </li>
            <li>
              <a
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "javascript:void(0)"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <img src={Whatapps} alt="Calling icon" />
                <span>Whatsapp</span>
              </a>
            </li>
            <li>
              <a
                href={
                  appDetails?.TELEGRAM != ""
                    ? appDetails?.TELEGRAM
                    : "javascript:void(0)"
                }
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <img src={Telegram} alt="Calling icon" />
                <span>Telegram</span>
              </a>
            </li>
          </ul>
        </Container>
      </div>
      <footer className="footer">
        <Container>
          <div className="sectionDivide">
            <div className="singleSection">
              <div className="footer-logo">
                <a href="#">
                  <img src={appDetails?.LOGO_URL || logo} alt="Logo" />
                </a>
                <div className="social-icons">
                  <ul>
                    <li>
                      <a
                        href={
                          appDetails?.FACEBOOK != ""
                            ? appDetails?.FACEBOOK
                            : "javascript:void(0)"
                        }
                        target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                      >
                        <img src={FbIconImg} />
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          appDetails?.INSTATGRAM != ""
                            ? appDetails?.INSTATGRAM
                            : "javascript:void(0)"
                        }
                        target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                      >
                        <img src={InstaIconImg} />
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          appDetails?.TELEGRAM != ""
                            ? appDetails?.TELEGRAM
                            : "javascript:void(0)"
                        }
                        target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                      >
                        <img src={TelegramImg} />
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          appDetails?.WHATS_APP_URL != ""
                            ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                            : "javascript:void(0)"
                        }
                        target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                      >
                        <img src={WhatsaPPImg} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="foot-discription">
                <p>
                  We are dedicated to making sure you have a great time playing
                  games on cbtfcasino.com while also being well aware of the
                  negative social and financial implications of excessive
                  gaming. We provide and advertise our games as delightful forms
                  of entertainment because we think that only when players play
                  sensibly and in moderation will gaming remain as such.
                </p>
              </div>
            </div>
            <div className="singleSection">
              <div className="providers_logo">
                <h4>Gaming Partner</h4>
                <ul>
                  <li>
                    <img src={EvolutionGames} alt="evolution game" />
                  </li>
                  <li>
                    <img src={EzugiGames} alt="ezugi game" />
                  </li>
                  <li>
                    <img src={VivoGames} alt="vivo game" />
                  </li>
                  <li>
                    <img src={QtechGames} alt="qtech game" />
                  </li>
                  <li>
                    <img src={WCGames} alt="xpg game" />
                  </li>
                  <li>
                    <img src={XPGhGames} alt="xpg game" />
                  </li>
                </ul>
              </div>
              <div className="paymentspartner">
                <h5>Payments Partners</h5>
                <ul>
                  <li>
                    <img src={UPIcon} />
                  </li>
                  <li>
                    <img src={GooglaPay} />
                  </li>
                  <li>
                    <img src={Paytm} />
                  </li>
                  <li>
                    <img src={Phonepay} />
                  </li>
                  <li>
                    <img src={Bitcoin} />
                  </li>
                  <li>
                    <img src={therthnt} />
                  </li>
                  <li>
                    <img src={pmicon} />
                  </li>
                  <li>
                    <img src={binance} />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Container>

        <div className="footer-links">
          <Container>
            <Row>
              <Col xs={12}>
                <ul>
                  <li>
                    <a href="/contact">Contact Us</a>
                  </li>
                  <li>
                    <a href="/privacypolicy">Privacy Policy</a>
                  </li>

                  <li>
                    <a href="/ResponsibleGaming">Responsible Gaming</a>
                  </li>
                  <li>
                    <a href="/fairplay">Fair Play</a>
                  </li>
                  <li>
                    <a href="/gamerules">Games Rules</a>
                  </li>
                  <li>
                    <a href="/termsandcondition">Terms and Conditions</a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <Row>
            <Col xs={12}></Col>
          </Row>
        </Container>

        <div>
          <BottomNav />
        </div>
      </footer>
    </>
  );
};

export default Footer;
