import React from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

import Dropdown from "react-bootstrap/Dropdown";
import { AiFillHeart } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import BackIcon from "../../../../assets/svg/BackIcon";
import OTTAndarBahar from "../../../../assets/images/andarbahar/Ott-andar-bahar.jpg";
import AndarBahar from "../../../../assets/images/andarbahar/Andar-bahar.jpg";
import UltimateAndarBahar from "../../../../assets/images/andarbahar/Ultimate-Andar-Bahar.jpg";

import BaccaratB from "../../../../assets/images/baccarat/Baccarat-B.jpg";
import BaccaratC from "../../../../assets/images/baccarat/Baccarat-C.jpg";
import GoldenWealthBaccarat from "../../../../assets/images/baccarat/Golden-Wealth-Baccarat.jpg";
import LightningBaccarat from "../../../../assets/images/baccarat/Lightning-Baccarat.jpg";
import NoCommBaccarat1 from "../../../../assets/images/baccarat/No-Commission-Baccarat.jpg";
import NoCommSpeedBaccaratA from "../../../../assets/images/baccarat/No-Commission-Speed-Baccarat-A.jpg";
import NoCommSpeedBaccaratB from "../../../../assets/images/baccarat/No-Commission-Speed-Baccarat-B.jpg";
import SalonPriveBaccarat from "../../../../assets/images/baccarat/Salon-Prive-Baccarat-C.jpg";
import SpeedBaccaratA from "../../../../assets/images/baccarat/Speed-Baccarat-A.jpg";
import SpeedBaccaratB from "../../../../assets/images/baccarat/Speed-Baccarat-B.jpg";
import SpeedBaccaratC from "../../../../assets/images/baccarat/Speed-Baccarat-C.jpg";
import SpeedBaccaratE from "../../../../assets/images/baccarat/Speed-Baccarat-E.jpg";

import Baccarat1 from "../../../../assets/images/baccarat/Baccarat.jpg";
import FortuneBaccarat from "../../../../assets/images/baccarat/Fortune-Baccarat.jpg";
import SpeedCricketBaccarat from "../../../../assets/images/baccarat/Speed-Cricket-Baccarat.jpg";
import VIPFortuneBaccarat from "../../../../assets/images/baccarat/VIP-Fortune-Baccarat.jpg";
import NoCommBaccarat from "../../../../assets/images/baccarat/No-Commission-Baccarat.jpg";
import FiestaBaccarat from "../../../../assets/images/baccarat/Fiesta-Baccarat.jpg";
import CasinoMarinaBaccarat1 from "../../../../assets/images/baccarat/Casino-Marina-Baccarat-1.jpg";
import CasinoMarinaBaccarat2 from "../../../../assets/images/baccarat/Casino-Marina-Baccarat-2.jpg";
import CasinoMarinaBaccarat3 from "../../../../assets/images/baccarat/Casino-Marina-Baccarat-3.jpg";
import CasinoMarinaBaccarat4 from "../../../../assets/images/baccarat/Casino-Marina-Baccarat-4.jpg";

import SpeedRoulette from "../../../../assets/images/roulette/Speed-Roulette.jpg";
import NamasteRoulette from "../../../../assets/images/roulette/Namaste-Roulette.jpg";
import AutoRoulette from "../../../../assets/images/roulette/Auto-Roulette.jpg";
import SpeedAutoRoulette from "../../../../assets/images/roulette/Speed-Auto-Roulette.jpg";
import DiamondRoulette from "../../../../assets/images/roulette/Diamond-Roulette.jpg";
import PrestigeRoulette from "../../../../assets/images/roulette/Prestige-Auto-Roulette.jpg";
import ItalianRoulette from "../../../../assets/images/roulette/Italian-Roulette.jpg";
import RussianRoulette from "../../../../assets/images/roulette/Russian-Roulette.jpg";
import TurkishRoulette from "../../../../assets/images/roulette/Turkish-Roulette.jpg";
import OracleRoulette from "../../../../assets/images/roulette/Oracle-360-Roulette.jpg";

import XtremeLightningRoulette from "../../../../assets/images/roulette/XXXtreme-Lightning-Roulette.jpg";
import LightningRoulette from "../../../../assets/images/roulette/Lightning-Roulette.jpg";
import InstantRoulette from "../../../../assets/images/roulette/Instant-Roulette.jpg";
import AmericanRoulette from "../../../../assets/images/roulette/American-Roulette.jpg";
import FootballStudioRoulette from "../../../../assets/images/roulette/Football-Studion-Roulette.jpg";
import Roulette1 from "../../../../assets/images/roulette/Roulette.jpg";
import DoubleBallRoulette from "../../../../assets/images/roulette/Double-ball-Roulette.jpg";
import DragonaraRoulette from "../../../../assets/images/roulette/Dragonara-Roulette.jpg";
import SalonPriveRoulette from "../../../../assets/images/roulette/Salon-Prive-Roulette.jpg";

import Blackjack1 from "../../../../assets/images/blackjack/Blackjack-1.jpg";
import Blackjack2 from "../../../../assets/images/blackjack/Blackjack-2.jpg";
import BlackjackGold4 from "../../../../assets/images/blackjack/Gold-Blackjack-4.jpg";
import BlackjackGold6 from "../../../../assets/images/blackjack/Blackjack-Gold-6.jpg";
import BlackjackPlatinum1 from "../../../../assets/images/blackjack/Blackjack-Platinum-1.jpg";
import ItalianBlackjack from "../../../../assets/images/blackjack/Italian-Blackjack.jpg";
import TurkishBlackjack from "../../../../assets/images/blackjack/Turkish-Blackjack.jpg";
import UnlimitedBlackjack from "../../../../assets/images/blackjack/Unlimited-Blackjack.jpg";
import VIPDiamondBlackjack from "../../../../assets/images/blackjack/VIP-Diamond-Blackjack.jpg";
import VIPBlackjack2 from "../../../../assets/images/blackjack/VIP-Blackjack-2.jpg";

import BlackjackA from "../../../../assets/images/blackjack/Blackjack-A.jpg";
import BlackjackB from "../../../../assets/images/blackjack/Blackjack-B.jpg";
import BlackjackC from "../../../../assets/images/blackjack/Blackjack-C.jpg";
import LightBlackjack from "../../../../assets/images/blackjack/Lightning-Blackjack.jpg";
import SalonPriveBlackjackA from "../../../../assets/images/blackjack/Salon-Privac-Blackjack-A.jpg";
import FreebetBlackjack from "../../../../assets/images/blackjack/Freebet-Blackjack.jpg";
import BlackjackVIP1 from "../../../../assets/images/blackjack/Blackjack-VIP-1.jpg";
import Craps from "../../../../assets/images/blackjack/Craps.jpg";
import InfiniteBlackjack from "../../../../assets/images/blackjack/Infinite-Blackjack.jpg";
import SalonPriveBlackjackB from "../../../../assets/images/blackjack/Salon-Privac-Blackjack-B.jpg";
import SalonPriveBlackjackC from "../../../../assets/images/blackjack/Salon-Privac-Blackjack-C.jpg";
import SpeedBlackjackD from "../../../../assets/images/blackjack/Speed-Blackjack-D.jpg";

import RouletteSupernowa from "../../../../assets/images/roulette/RouletteSupernowa.jpg";
import BaccaratSupernowa from "../../../../assets/images/baccarat/BaccaratSupernowa.jpg";
import RNGBaccarat2020Supernowa from "../../../../assets/images/baccarat/RNG-Baccarat-2020.jpg";
import RNGBaccaratSupernowa from "../../../../assets/images/baccarat/RNG-Baccarat.jpg";
import AndarBaharSupernowa from "../../../../assets/images/andarbahar/Andar-BaharSupernowa.jpg";
import ClassicAndarBaharSupernowa from "../../../../assets/images/andarbahar/Classic-Andar-Bahar.jpg";
import GoasAndarBaharSupernowa from "../../../../assets/images/andarbahar/Goa's-Andar-Bahar.jpg";
import RNGAndarBaharSupernowa from "../../../../assets/images/andarbahar/RNG-Andar-Bahar-2020.jpg";

const AllGamePage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>

      <main className="main">
        <div className="container">
          <div className="d-flex justify-content-between">
            <h2 className="sectionTitle border-0">All Games</h2>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>
          <div className="game-page">
            <div className="filter-added">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  All
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">All</Dropdown.Item>
                  <Dropdown.Item href="/casino/spribe/aviator">
                    Fast Game
                  </Dropdown.Item>
                  <Dropdown.Item href="/live-dealer">Live Dealer</Dropdown.Item>
                  <Dropdown.Item href="/casino/evolution">
                    Game Shows
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  All
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="/casino/ezugi">Ezugi</Dropdown.Item>

                  <Dropdown.Item href="/casino/evolution">
                    {" "}
                    Evolution
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="favrouite">
                <AiFillHeart />
              </div>
            </div>
            <div className="provider-game-name">
              <h2 class="sectionTitle">Ezugi</h2>
            </div>
            <div className="provider-game-section">
              <ul>
                <li>
                  <a href="/casino/ezugi/Andarbahar">
                    <img src={AndarBahar} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/UltimateAndarbahar">
                    <img src={UltimateAndarBahar} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/OTTAndarbahar">
                    <img src={OTTAndarBahar} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/Baccarat">
                    <img src={Baccarat1} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/FortuneBaccarat">
                    <img src={FortuneBaccarat} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/SpeedCricketBaccarat">
                    <img src={SpeedCricketBaccarat} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/FortuneBaccarat">
                    <img src={VIPFortuneBaccarat} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/NoCommissionBaccarat">
                    <img src={NoCommBaccarat} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/FiestaBaccarat">
                    <img src={FiestaBaccarat} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/CasinoMariniaBaccarat1">
                    <img src={CasinoMarinaBaccarat1} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/CasinoMariniaBaccarat2">
                    <img src={CasinoMarinaBaccarat2} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/CasinoMariniaBaccarat3">
                    <img src={CasinoMarinaBaccarat3} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/CasinoMariniaBaccarat4">
                    <img src={CasinoMarinaBaccarat4} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/SpeedRoulette">
                    <img src={SpeedRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/NamasteRoulette">
                    <img src={NamasteRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/AutoRoulette">
                    <img src={AutoRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/SpeedAutoRoulette">
                    <img src={SpeedAutoRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/DiamondRoulette">
                    <img src={DiamondRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/PrestigeRoulette">
                    <img src={PrestigeRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/ItalianRoulette">
                    <img src={ItalianRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/RussianRoulette">
                    <img src={RussianRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/TurkishRoulette">
                    <img src={TurkishRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/OracleRoulette">
                    <img src={OracleRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/Blackjack1">
                    <img src={Blackjack1} />
                  </a>
                </li>

                <li>
                  <a href="/casino/ezugi/BlackjackGold6">
                    <img src={BlackjackGold6} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/BlackjackPlatinum1">
                    <img src={BlackjackPlatinum1} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/BlackjackGold4">
                    <img src={BlackjackGold4} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/ItalianBlackjack">
                    <img src={ItalianBlackjack} />
                  </a>
                </li>

                <li>
                  <a href="/casino/ezugi/UnlimitedBlackjack">
                    <img src={UnlimitedBlackjack} />
                  </a>
                </li>
                {/* <li>
                  <a href="/casino/ezugi/VIPBlackjack2">
                    <img src={VIPBlackjack2} />
                  </a>
                </li> */}
                <li>
                  <a href="/casino/ezugi/VIPDiamomdBlackjack">
                    <img src={VIPDiamondBlackjack} />
                  </a>
                </li>
              </ul>
            </div>
            <div className="provider-game-name">
              <h3>Evolution</h3>
            </div>
            <div className="provider-game-section">
              <ul>
                <li>
                  <a href="/casino/evolution/BaccaratB">
                    <img src={BaccaratB} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/BaccaratC">
                    <img src={BaccaratC} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/GoldenWealthBaccarat">
                    <img src={GoldenWealthBaccarat} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/LightningBaccarat">
                    <img src={LightningBaccarat} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/NoCommBaccarat">
                    <img src={NoCommBaccarat1} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/NoCommSpeedBaccarat">
                    <img src={NoCommSpeedBaccaratA} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/NoCommSpeedBaccarat">
                    <img src={NoCommSpeedBaccaratB} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/SalonPriveBaccarat">
                    <img src={SalonPriveBaccarat} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/SpeedBaccaratA">
                    <img src={SpeedBaccaratA} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/SpeedBaccaratB">
                    <img src={SpeedBaccaratB} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/SpeedBaccaratC">
                    <img src={SpeedBaccaratC} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/SpeedBaccaratE">
                    <img src={SpeedBaccaratE} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/BaccaratB">
                    <img src={XtremeLightningRoulette} />
                  </a>
                </li>
                <li>
                  <a href="casino/evolution/XtremeLightningRoulette">
                    <img src={LightningRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/InstantRoulette">
                    <img src={InstantRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/AmericanRoulette">
                    <img src={AmericanRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/FootballStudioRoulette">
                    <img src={FootballStudioRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/Roulette1">
                    <img src={Roulette1} />
                  </a>
                </li>

                <li>
                  <a href="/casino/evolution/DoubleballRoulette">
                    <img src={DoubleBallRoulette} />
                  </a>
                </li>
                {/* <li>
                  <a href="/casino/evolution/DragonaraRoulette">
                    <img src={DragonaraRoulette} />
                  </a>
                </li> */}
                <li>
                  <a href="/casino/evolution/SalonPriveRoulette">
                    <img src={SalonPriveRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/BlackjackA">
                    <img src={BlackjackA} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/BlackjackB">
                    <img src={BlackjackB} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/BlackjackC">
                    <img src={BlackjackC} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/BlackjackVIP1">
                    <img src={BlackjackVIP1} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/Craps">
                    <img src={Craps} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/FreebetBlackjack">
                    <img src={FreebetBlackjack} />
                  </a>
                </li>

                <li>
                  <a href="/casino/evolution/InfiniteBlackjack">
                    <img src={InfiniteBlackjack} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/LightningBlackjack">
                    <img src={LightBlackjack} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/SalonPriveBlackjackA">
                    <img src={SalonPriveBlackjackA} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/SalonPriveBlackjackB">
                    <img src={SalonPriveBlackjackB} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/SalonPriveBlackjackC">
                    <img src={SalonPriveBlackjackC} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/SpeedBlackjackD">
                    <img src={SpeedBlackjackD} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default AllGamePage;
